<template>
  <div class="create-content-main">
    <div class="create-form">
      <div
        class="cpx-4 cpy-2 font-title"
        v-if="idMailMega === null || idMailMega === undefined"
      >
        登録メルマガ個別設定新規作成
      </div>
      <div
        class="cpx-4 cpy-2 font-title"
        v-else-if="idMailMega !== null || idMailMega !== undefined"
      >
        登録メルマガ個別設定編集
      </div>
      <form
        class="px-3"
        v-on:submit.prevent="CreateNewContent()"
        id="create-content"
        autocomplete="off"
      >
        <div class="row row-input">
          <label
            class="col-sm-12 d-flex align-items-center cp-label"
            style="background: #d5d5d5"
            ><span style="font-weight: bolder">ステップ１: </span
            >&ensp;タイトル<span class="required">(必須)</span></label
          >
          <div class="cp-4 w-100 mb-0">
            <b-form-input
              class="col-sm-12 mb-0"
              v-model="title"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="row row-input d-flex align-items-center">
          <label
            class="col-sm-12 d-flex align-items-center cp-label"
            style="background: #d5d5d5"
            ><span style="font-weight: bolder">ステップ2: </span>&ensp;
            フォームHTML<span class="required">(必須)</span></label
          >
          <div class="cp-4 w-100">
            <CTextarea
              class="col-sm-12 px-0 mb-0"
              rows="10"
              v-model="text_HTML"
              @change="setValueSelectBox(text_HTML)"
            />
          </div>
        </div>
        <div class="row row-input">
          <label
            class="col-sm-12 d-flex align-items-center cp-label"
            style="background: #d5d5d5"
            ><span style="font-weight: bolder">ステップ3: </span>&ensp;
            NAME<span class="required">(必須)</span></label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="name"
              tag-placeholder="Add this as new category"
              :options="dataOptionName"
              class="col-sm-12 mb-0"
              style="color: black"
            ></b-form-select>
          </div>
        </div>

        <div class="row row-input">
          <label
            class="col-sm-12 d-flex align-items-center cp-label"
            style="background: #d5d5d5"
            ><span style="font-weight: bolder">ステップ4: </span>&ensp;
            EMAIL<span class="required">(必須)</span></label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="email"
              tag-placeholder="Add this as new category"
              :options="dataOptionEmail"
              class="col-sm-12 mb-0"
              style="color: black"
            ></b-form-select>
          </div>
        </div>
        <div class="row row-input">
          <label
            class="col-sm-12 d-flex align-items-center cp-label"
            style="background: #d5d5d5"
            ><span style="font-weight: bolder">ステップ5: </span>&ensp;
            METHOD<span class="required">(必須)</span></label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="method"
              tag-placeholder="Add this as new category"
              :options="dataOptionMethod"
              class="col-sm-12 mb-0"
              style="color: black"
            ></b-form-select>
          </div>
        </div>
        <div class="row row-input">
          <label
            class="col-sm-12 d-flex align-items-center cp-label"
            style="background: #d5d5d5"
            ><span style="font-weight: bolder">ステップ6: </span>&ensp;
            ACTION<span class="required">(必須)</span></label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="action"
              tag-placeholder="Add this as new category"
              :options="dataOptionAction"
              class="col-sm-12 mb-0"
              style="color: black"
            ></b-form-select>
          </div>
        </div>
        <div class="row row-input d-flex align-items-center">
          <label
            class="col-sm-12 d-flex align-items-center cp-label"
            style="background: #d5d5d5"
            ><span style="font-weight: bolder">ステップ7: </span>&ensp;
            HIDDEN</label
          >
          <div class="cp-4 w-100">
            <CTextarea class="col-sm-12 pl-0 px-0" rows="10" v-model="hidden" />
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4 pb-4">
        <b-button
          v-on:click.prevent="CreateNewMailMega()"
          :disabled="isLoading"
          class="mr-4 btn-color mb-0"
          variant="success"
          >登録・更新する</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateCategory",
  data() {
    return {
      title: "",
      text_HTML: "",
      name: "",
      email: "",
      method: "",
      action: "",
      hidden: "",
      dataOptionName: [],
      dataOptionEmail: [],
      dataOptionMethod: [],
      dataOptionAction: [],
      dataOptionHidden: [],
      isLoading: false,
      idMailMega: this.$route.params.id,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    const requestContent = {
      shop_id: this.shop_id,
      id: this.$route.params.id,
    };
    this.getMailMegaById(requestContent);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters(["success", "message", "error", "mailMegaById"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.isLoading = false;
      }
    },
    mailMegaById() {
      this.title = this.mailMegaById.title;
      this.text_HTML = this.mailMegaById.form_html;
      this.setValueSelectBox(this.mailMegaById.form_html);
      this.name = this.mailMegaById.name;
      this.email = this.mailMegaById.email;
      this.method = this.mailMegaById.method;
      this.action = this.mailMegaById.action;
      this.hidden = this.mailMegaById.hidden;
    },
  },
  methods: {
    ...mapActions({ createMailMega: "createMailMega" }),
    ...mapActions({ getMailMegaById: "getMailMegaById" }),
    ...mapActions({ updateMailMegaByID: "updateMailMegaByID" }),
    async CreateNewMailMega() {
      this.isLoading = true;
      const shop_id = this.shop_id;
      const id = this.$route.params.id;
      const formData = {
        id: id,
        shop_id: shop_id,
        title: this.title,
        form_html: this.text_HTML,
        name: this.name,
        email: this.email,
        method: this.method,
        category_id: this.category_ID,
        action: this.action,
        hidden: this.hidden,
      };
      if (id === null || id === undefined) {
        const dataReturn = await this.$store.dispatch(
          "createMailMega",
          formData
        );
        if (dataReturn.success) {
          this.$router.go(-1);
        } else {
          this.$toasted.error(this.message);
          this.isLoading = false;
        }
      } else {
        const dataReturn = await this.$store.dispatch(
          "updateMailMegaByID",
          formData
        );
        if (dataReturn.success) {
          this.$router.go(-1);
        } else {
          this.$toasted.error(this.message);
          this.isLoading = false;
        }
      }
    },
    returnList() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "List Mail Mega"
          : "List Mail Mega domain",
      });
    },
    setValueSelectBox(value) {
      let dataHTML = [];
      let modalinputHidden = "";
      this.dataOptionName = [];
      this.dataOptionEmail = [];
      this.dataOptionAction = [];
      this.dataOptionHidden = [];
      this.dataOptionMethod = [];
      dataHTML = new DOMParser()
        .parseFromString(value, "text/html")
        .getElementsByTagName("*");
      for (let i = 0; i < dataHTML.length; i++) {
        if (dataHTML[i].getAttribute("name") !== null) {
          let dataOptionName = {
            value: null,
            text: null,
          };
          dataOptionName.value = dataHTML[i].getAttribute("name");
          dataOptionName.text = dataHTML[i].getAttribute("name");
          this.dataOptionName.push(dataOptionName);
          this.dataOptionEmail.push(dataOptionName);
        }
        if (dataHTML[i].getAttribute("method") !== null) {
          let dataOptionMethod = {
            value: null,
            text: null,
          };
          dataOptionMethod.value = dataHTML[i].getAttribute("method");
          dataOptionMethod.text = dataHTML[i].getAttribute("method");
          this.dataOptionMethod.push(dataOptionMethod);
        }
        if (dataHTML[i].getAttribute("action") !== null) {
          let dataOptionAction = {
            value: null,
            text: null,
          };
          dataOptionAction.value = dataHTML[i].getAttribute("action");
          dataOptionAction.text = dataHTML[i].getAttribute("action");
          this.dataOptionAction.push(dataOptionAction);
        }
        if (
          dataHTML[i].getAttribute("type") !== null &&
          dataHTML[i].getAttribute("type") === "hidden"
        ) {
          modalinputHidden += dataHTML[i].outerHTML + "\n";
        }
      }
      this.hidden = modalinputHidden;
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.row-input input {
  margin: 0px !important;
}
.cp-label {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
</style>
